import React from "react"
import Layout from "../components/layout"
import SEO from "../components/Seo"

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO
      title= {'Kebijakan Privasi - MAJAHNEWS'}
      description= {'Untuk mengetahui lebih dalam tentang pasaran togel, MAJAHNEWS akan membantu anda menemukan informasi terbaik tentang pasaran togel di Indonesia.'}/>
      <h1>Kebijakan Privasi</h1>
      <p>
      Alamat situs web kami adalah: <a href="https://majahnews.com">https://majahnews.com</a>
      </p>
      <h2>
      Data pribadi apa yang kami kumpulkan dan mengapa kami mengumpulkannya
      </h2>
      <h3>
      Komentar
      </h3>
      <p>
      Ketika pengunjung meninggalkan komentar pada situs tersebut, kami mengumpulkan data yang ditampilkan dalam kolom komentar, dan juga alamat IP pengunjung dan string agen pengguna browser untuk membantu mendeteksi spam.
      </p>
      <p>
      Sebuah string anonym dibuat dari alamat email Anda (juga disebut sebagai hash) dapat diberikan ke layanan Gravatar untuk melihat apakah Anda sedang menggunakannya. Layanan kebijakan privasi Gravatar tersedia disini: https://automattic.com/privacy/. Setelah persetujuan atas komentar Anda, foto profil Anda dapat dilihat oleh public dalam konteks komentar Anda.
      </p>
      <h3>Formulir kontak</h3>
      <p>Informasi yang dikirimkan melalui formulir kontak pada situs kami dikirimkan ke email perusahaan kami, yang di hosting oleh Gmail. Google mematuhi kebijakan EU “Perisai Privasi” dan Anda dapat menemukan informasi lebih lanjut mengenai hal tersebut disini.</p>
      <p>Meskipun kami menyimpan kiriman ini untuk tujuan layanan pelanggan, mereka tidak pernah digunakan untuk tujuan pemasaran atau dibagikan kepada pihak ketiga.</p>
      <h3>Cookies</h3>
      <p>Ketika Anda mengunjungi Situs tersebut, server kami secara otomatis mencatat informasi non-pribadi berdasarkan penggunaan Anda pada situs tersebut. Informasi ini mungkin termasuk, namun tidak terbatas pada, alamat IP komputer Anda, tipe browser. Kami menggunakan “cookies” untuk mengumpulkan informasi untuk menyediakan dan meningkatkan pelayanan, fitur dan konten dari situs tersebut. Juga, Situs dapat mengumpulkan informasi melalui situs Mitra.</p>
      <p>Situs kami menggunakan cookies sebagai berikut:</p>
      <li>Google Analytics cookies - ini diatur untuk memonitor dan melacak perilaku pengunjung pada situs tersebut.</li>
      <li>Pro cookies - ini diatur untuk keperluan anti spam, jadi orang-orang yang berkomentar ulasan dan artikel tidak dapat menyalahgunakan layanannya.</li>
      <h3>Konten yang disematkan pada situs lainnya</h3>
      <p>Artikel pada situs ini dapat menyertakan konten yang disematkan (contohnya video, gambar, artikel, dll). Konten yang disematkan dari situs lain berperilaku dengan cara yang persis sama seolah-olah pengunjung telah mengunjungi situs web lain.</p>
      <p>Situs web ini dapat mengumpulkan data tentang Anda, menggunakan cookie, menyematkan pelacakan pihak ketiga tambahan, dan memantau interaksi Anda dengan konten yang disematkan tersebut, termasuk melacak interaksi Anda dengan konten yang disematkan jika Anda memiliki akun dan masuk ke situs web itu.</p>
      <h2>Dengan siapa kami berbagi data Anda</h2>
      <p>Kami tidak membagikan data Anda dengan pihak ketiga dengan cara mengungkapkan informasi pribadi Anda seperti email, nama, dll. Namun, beberapa data ditransfer dan/atau disimpan dengan layanan pihak ketiga yang kami gunakan, seperti Google Analytics , Google Tag Manager, dll. Ini dilakukan sebagai cara untuk memberi Anda layanan dan pengalaman pengguna yang lebih baik secara keseluruhan.</p>
      <h2>Bagaimana kami melindungi data Anda</h2>
      <p>Kami melindungi data pelanggan dengan fitur situs berikut ini:</p>
      <li>Kami sepenuhnya menggunakan SSL/HTTPS diseluruh situs-situs kami. Ini mengenkripsi komunikasi pengguna kami dengan server sehingga informasi identitas pribadi tidak pernah disimpan oleh pihak ketiga tanpa otorisasi.</li>
      <li>Database tersanitasi (rincian aktual pribadi pengguna tidak disimpan) sebelum diterapkan ke lingkungan pengembangan atau pengujian.</li>
      <h2>Hak apa yang Anda miliki atas data Anda</h2>
      <p>Jika Anda memiliki akun di situs ini atau telah meninggalkan komentar, Anda dapat meminta untuk menerima file yang diekspor dari data pribadi yang kami miliki tentang Anda, termasuk data apa pun yang telah Anda berikan kepada kami.</p>
      <p>Anda juga dapat meminta kami menghapus semua data pribadi yang kami miliki tentang Anda. Ini tidak termasuk data apa pun yang wajib kami simpan untuk tujuan administratif, hukum, atau keamanan. Singkatnya, kami tidak dapat menghapus data yang penting mengenai Anda menjadi pelanggan kami (yaitu informasi akun dasar seperti alamat email).</p>
      <h2>Perubahan pada Kebijakan Privasi</h2>
      <p>Kami dapat memodifikasi kebijakan tersebut kapan saja.</p>
      <h2>Kontak</h2>
      <p>Jika Anda mempunyai pertanyaan tentang Kebijakan Privasi ini, silakan kirim e-mail kepada kami di info@majahnews.com</p>
    </Layout>
  )
}

export default PrivacyPage
